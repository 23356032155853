/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


jQuery(function($) {
  const quizWrap = $('.page-template-template-services .quiz-main-wrap');
  var currentStep = 1;

  quizWrap.find('.step a').on('click', function(e) {
    e.preventDefault();
  });

  quizWrap.find('.single-question .btn').on('click', function(e) {
    e.preventDefault();

    // Validate step
    var selected = $(this).closest('form').find('input[type=radio]:checked');

    if (selected.length <= 0) {
      $(this).closest('form').addClass('invalid');
      return;
    }
    currentStep += 1;

    quizWrap.find('.single-question.active').removeClass('active');

    const nextStep = quizWrap.find('.step-' + currentStep);

    if (nextStep.length > 0) {
      nextStep.addClass('active');
      quizWrap.find('.single-question.question-' + currentStep).addClass('active');
    } else {
      // show resutls
      quizWrap.addClass('finished');

      var answersField = quizWrap.find('form .answers textarea');
      var quizSteps = quizWrap.find('.single-question');
      var answersString = '';

      quizSteps.each(function() {
        var question = $(this).find('h4').text();
        var answer = $(this).find('input[type=radio]:checked').val();
        answersString += question + '\n' + answer + '\n\n';
      });

      answersField.val(answersString);
    }
  });

  $('.menu-toggle').on('click', function(e){
    e.preventDefault();
    $('header.banner .nav-primary').toggleClass('visible');
    $('html').toggleClass('menu-visible');
  });

  $('.menu-close, .mobile-nav-overlay').on('click', function(e){
    e.preventDefault();
    $('html').removeClass('menu-visible');
    $('header.banner .nav-primary').removeClass('visible');
  });
});


jQuery(window).scroll(function() {
  const $ = jQuery;
  const scroll = $(window).scrollTop();
  const hasClass = $('body').hasClass('scrolled');

  if (scroll > 30 && !hasClass) {
    $('body').addClass('scrolled');
  } else if (scroll < 30 && hasClass) {
    $('body').removeClass('scrolled');
  }
});


jQuery(function() {
  const $ = jQuery;
  var stepButtons = $('.step-list .step');
  var stepContent = $('.step-content .step');

  stepButtons.on('click', function(e) {
    e.preventDefault();

    stepButtons.removeClass('active');
    $(this).addClass('active');

    var newStep = $(this).data('step');

    stepContent.removeClass('active');
    stepContent.filter('.step-'+newStep).addClass('active');
  });
});

jQuery(function($) {
  $('.plan-tabs .plan-tab h3').on('click', function(e) {
    $(this).parent().toggleClass('open');
  });
});


jQuery(function($) {
  if ($('body').hasClass('page-id-1691')) {
    var now = new Date();
    var day = now.getDate();
    var left = 1;
    if (day < 10) {
      left = 5;
    }
    else if (day < 15) {
      left = 3;
    }
    else if (day < 24) {
      left = 2;
    }

    $('.contact-form-wrap form h4 span').text(left);
  }
});


jQuery(function($) {
  $('.mobile-onpage-nav a, .anchor-link').on('click', function(e) {
    e.preventDefault();
    var headerHeight = $('header.banner').height() + 10;

    $('html, body').animate({
  		scrollTop: $($(this).attr('href')).offset().top - headerHeight
  	}, 500);
  });

  if (window.innerWidth < 1000) {
    $('.nav-primary li.menu-item-has-children > a').on('click', function(e) {

      var linkWrap = $(this).parent();
      if (!linkWrap.hasClass('opened')) {
        e.preventDefault();
        $(this).parent().addClass('opened');
        return false;
      }
    });
  }

  if (window.innerWidth < 768) {
    var vpList = $('.page-template-template-agency .value-prop .vp-list');
    vpList.find('.col-lg-6').first().addClass('opened');

    vpList.find('.col-lg-6').on('click', function(e) {
      e.preventDefault();

      if (!$(this).hasClass('opened')) {
        vpList.find('.opened').removeClass('opened');
        $(this).addClass('opened');
      }
    });
  }
});


jQuery(function($) {
  if (window.innerWidth < 768) {
    $('.hp-testimonials-wrap').slick({
      arrows: false,
      dots: true,
      adaptiveHeight: true
    });

    $('.case-study-section .section-content .row').slick({
      arrows: false,
      dots: true,
      adaptiveHeight: true
    });
  }


  $('.page-template-template-agency .testimonial-list').slick({
    arrows: true,
    dots: true,
    adaptiveHeight: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          arrows: false
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  });
});


// Our Approach
jQuery(function($) {
  if ($('body').hasClass('page-template-template-approach')) {
    particlesJS("particles", {
      "particles": {
        "number": {
          "value": 200,
          "density": {
            "enable": true,
            "value_area": 800
          }
        },
        "color": {
          "value": "#ffffff"
        },
        "opacity": {
          "value": 0.5,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 1,
            "opacity_min": 0.1,
            "sync": false
          }
        },
        "size": {
          "value": 3,
          "random": true,
          "anim": {
            "enable": false,
            "speed": 40,
            "size_min": 0.1,
            "sync": false
          }
        },
        "line_linked": {
          "enable": true,
          "distance": 150,
          "color": "#ffffff",
          "opacity": 0.4,
          "width": 1
        },
        "move": {
          "enable": true,
          "speed": 1,
          "direction": "none",
          "random": false,
          "straight": false,
          "out_mode": "out",
          "bounce": false,
          "attract": {
            "enable": false,
            "rotateX": 600,
            "rotateY": 1200
          }
        }
      },
      "retina_detect": true
    });
  }
});
